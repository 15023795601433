import { Injectable } from '@angular/core';

import { AlertController, LoadingController, ToastController } from '@ionic/angular';



@Injectable({
  providedIn: 'root'
})
export class CommonAlertService {
  isLoading=true;
  constructor(
    private toast: ToastController,
    private loader: LoadingController
  ) { }


  async successToast(message: string) {
    const toast = await this.toast.create({
      message,
      color: 'success',
      duration: 2000
    });

    toast.present();
  }

  async errorToast(message: string) {
    const toast = await this.toast.create({
      message,
      color: 'warning',
      duration: 2000
    });

    toast.present();
  }


  async startLoader() {
    if(this.isLoading) {
      const loader = await this.loader.create();
      await loader.present();
      this.isLoading=false;
    }

  }

  async stopLoader() {
    this.isLoading=true;
    return await this.loader.dismiss();
  }
}
