import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log(JSON.parse(localStorage.getItem('user')).role);
    if (
      JSON.parse(localStorage.getItem('user')).role === 'ADMIN'
    ) {
      return true;
    } else {
      return false;
    }
  }
}
