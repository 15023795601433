import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.translate.addLangs(['en-GB', 'nl-NL']);
    if (localStorage.getItem('lang')) {
      this.translate.setDefaultLang(localStorage.getItem('lang'));
    } else {
      this.translate.setDefaultLang('en-GB');
      localStorage.setItem('lang', 'en-GB');
    }


  }


}
