import { HttpClient,HttpHeaders,HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  #baseApiUrl = `${environment.apiBaseUrl}`;
  constructor(private http: HttpClient) {}



  getAuthToken() {
    const token = localStorage.getItem('token');
    const authType = localStorage.getItem('authType');
    const httpOptions = {
      headers: new HttpHeaders({
        ['AuthorizationToken']:  `${token}`,
        ['IssuingAuthority']: `${authType}`
      })
    };
    return this.http.get(`${this.#baseApiUrl}/Auth`,httpOptions);
  }



 logout() {
    // const user = JSON.parse(localStorage.getItem('user'));
    return this.http.delete(`${this.#baseApiUrl}/Auth/Logout`).toPromise();
  }

  changePreferredLanguage(lang: any) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http
      .get(`${this.#baseApiUrl}/User/prefferedlanguage?user=${user.id}&language=${lang}`)
      .toPromise();
  }

}
