import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoggedOutGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (localStorage.getItem('authToken')) {
      if (
        JSON.parse(localStorage.getItem('user')).role === 'ADMIN'
      ) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/home']);
      }

    } else {
      return true;
    }
  }
}
