export const common = {
  apiBaseUrl: 'https://uat.api.scaldex.app',
  apiVersion: '',
  apiEndpoints: {
    auth: `Auth`,
  },
  google: {
    scopes: ['profile', 'email'],
    clientId: '217356561380-do9pkpfg81skaqc4l920kkpri73p9l8u.apps.googleusercontent.com'
  },
  microsoft: {
    clientId: 'a5014176-602c-4266-ae45-053c2acd99e4'
  },
};
