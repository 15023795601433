import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedOutGuard, LoggedInGuard } from '@core/guards';
import { AdminGuard } from '@core/guards/admin.guard';
import { UserGuard } from '@core/guards/user.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/logged-out/logged-out.module').then(
        (m) => m.HomePageModule
      ),
    canActivate: [LoggedOutGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/logged-in/logged-in.module').then(
        (m) => m.LoggedInPageModule
      ),
    canActivate: [LoggedInGuard,UserGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminPageModule),
    canActivate: [LoggedInGuard,AdminGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundPageModule
      ),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
